@use 'ui/styles/variables' as vars;

.Dropdown {
  position: relative;
  z-index: 200;
  max-width: 80vw;
  width: fit-content;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  min-height: 300px;
  min-width: 200px;

  @include vars.mobileOnly {
    width: 100%;
  }

}