@use 'ui/styles/variables' as vars;

.DatePicker {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  container-type: inline-size;
  padding: 0px 8px;

  .Header {
    display: flex;
    gap: 8px;
    max-width: 200px;

    .YearDropdown {
      flex: 3;
    }
  }
}

.MonthPills {
  display: flex;
  width: 100%;
  gap: 8px 4px;
  justify-content: space-between;
  flex-wrap: wrap;

  button {
    width: calc(100%/6 - 4px);

    @container(width < 320px) {
      width: calc(100%/4 - 4px);
    }

    @container(width > 760px) {
      width: calc(100%/12 - 4px);
    }

  }
}



.DayNames {
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-weight: bold;
  color: var(--ui-mutedText);
  align-items: center;

  span {
    width: calc(100% / 7 - 1px);
    display: flex;
    justify-content: center;
  }
}

.Days {
  display: flex;
  width: 100%;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  li {
    display: flex;
    width: calc(100% / 7);
    justify-content: space-around;
    box-sizing: border-box;
    height: 2.75rem;
    align-items: center;
    border-bottom: 1px solid var(--ui-divider);
    display: block;
    text-align: center;
  }

  .Day {
    display: block;
    height: 2.75rem;
    padding-top: 7px;
    text-align: center;

    &.available:after {
      color: var(--core-blue)
    }

    &.active {
      border: 1px solid var(--ui-link);
      font-weight: bold;
      border-radius: 16px;
    }

    &:disabled {
      color: var(--ui-mutedText);
      background-color: #fff;
    }
  }

  .DayBtn {
    display: block;
    width: 100%;
    height: 2.75rem;
    text-align: center;
    padding-top: 0;
    border-radius: 50rem;


    &:not([disabled]):hover {
      background-color: var(--background-faintBlue);
    }

    &:focus {
      outline: 2px solid var(--ui-orange);
      outline-offset: -2px;
    }

    &:after {
      content: "•";
      height: 11px;
      display: block;
      color: transparent;
      margin-top: -.5rem;
    }
  }

  .DayLink {
    display: block;
    border-radius: 50rem;
    width: 100%;
    height: 2.75rem;
    text-align: center;
    padding-top: 7px;

    &:after {
      content: "•";
      height: 4px;
      display: block;
      color: transparent;
      margin-top: -.5rem;
    }

    &:hover {
      background-color: var(--background-faintBlue);
    }

    &.available:after {
      color: var(--core-blue)
    }
  }
}

.FullYearCalendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;

  @include vars.mediaQuery('md') {
    margin: 0 auto;
    max-width: 1200px;
  }

  h2 {
    color: var(--ui-mutedText);
    font-size: 1.5rem;
    margin: 0 0 2rem;
  }

  .monthGrid {
    height: fit-content;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 1rem;

    @include vars.mediaQuery('md') {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: space-around;
    }

    .month {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      margin: 1rem 0;

      @include vars.mediaQuery('md') {
        margin: 0;
      }

      h3 {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    @include vars.mediaQuery('md') {
      &>div {
        max-width: calc(33% - 64px);
      }
    }
  }


}