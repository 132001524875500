.LogomarkContainer {
  position: relative;
  line-height: .5em;
  color: var(--ui-mutedText) !important;
  filter: invert(100%) grayscale(100%) opacity(40%);

  span {
    color: var(--ui-mutedText);
    position: absolute;
    top: 1em;
    right: 0;
    font-size: .17em;
    line-height: .17em;
  }
}

.LogoIcon {
  width: 1em;
  height: 1em;
  fill: var(--ui-mutedText) !important;
}

.ScreenLogo {
  box-sizing: border-box;
  margin: 0px 24px 0px 16px;
  width: 156px;
  height: 43px;

  &.grayscale {
    filter: invert(100%) grayscale(100%) opacity(40%);
  }

  @media print {
    display: none;
  }
}

.PrintLogo {
  box-sizing: border-box;
  margin: 0px 24px 0px 16px;
  height: 40px;
  width: 145px;

  @media screen {
    display: none;
  }
}

.PoweredByLogo {
  @media print {
    filter: invert(100%) grayscale(100%) opacity(40%);
  }
}