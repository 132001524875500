.TextLink {
  font-family: var(--font-family);
  font-size: inherit;
  line-height: inherit;
  color: var(--ui-link);
  cursor: pointer;
  border-radius: 4px;
  padding: 0;

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
    outline-offset: 2px;
  }

  &:hover,
  &.hover {
    text-decoration: underline !important;
    color: var(--ui-hoverState) !important;

    svg {
      fill: var(--ui-hoverState) !important;
    }
  }

  //this is done for a11y
  p>& {
    text-decoration: underline;
  }
}