.TextButton {
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  max-height: 1.4em;

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
    outline-offset: 2px;
  }

  &.small {
    font-size: .8rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &.secondary {
    svg {
      fill: var(--ui-mutedText);
    }

    background: transparent;
    color: var(--ui-mutedText);
  }

  &.primary {
    svg {
      fill: var(--ui-link);
    }

    background: transparent;
    color: var(--ui-link);
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: var(--ui-disabledText);

    :hover,
    &.hover {
      color: var(--ui-disabledText);

      svg {
        fill: var(--ui-disabledText);
      }
    }

    svg {
      fill: var(--ui-disabledText);
    }
  }

  &:hover,
  &.hover {
    color: var(--ui-hoverState);
    text-decoration: none !important;

    svg {
      fill: var(--ui-hoverState);
    }
  }

}