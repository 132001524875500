@use "variables" as vars;

:root {
  --theme-palette-primary: #0079B7;
  --theme-palette-text-extra: #945F08;
  --theme-palette-text-primary: #2d2d2d;
  --theme-font-family: "Helvetica Neue", Helvetica, Arial;
  // Colors
  --alert-danger: #B51A00;
  --alert-dangerBackground: #F0D7D4;
  --alert-dangerDark: #7b181b;
  --alert-success: #107E2A;
  --alert-successBackground: #E2F7E7;
  --alert-warning: #FFC107;
  --alert-warningBackground: #FCF1D3;
  --background-beige: #fdf9f1;
  --background-faintBlue: #f6fbfe;
  --background-gray: #ebebeb;
  --background-highlight: #fbdcaa;
  --background-lightBlue: #e0f0f9;
  --background-lighter: #fafafa;
  --core-blue: #7ec2ec;
  --core-ink: #2d2d2d;
  --core-red: #bb2429;
  --core-slate: #4a4a4a;
  --ui-ancestryGreen: #328800;
  --ui-ancestryLeaf: #94bf13;
  --ui-ancestryHover: #276B00;
  --ui-bodyText: #2d2d2d;
  --ui-hoverState: #006091;
  --ui-link: #0079B7;
  --ui-linkVisited: #7b181b;
  --ui-marriage: #d237b5;
  --ui-mutedText: #6c6c6c;
  --ui-orange: #D57B12;
  --ui-orangeShadow: #D57B12b3;
  --ui-pe: #f6b040;
  --ui-peText: #945f08;
  --ui-divider: #dfdfdf;
  --ui-disabledText: #b1b1b1;
  --ui-highlight: #FCF04F;
  --font-family: "Helvetica Neue", Helvetica, Arial;
  //Values
  --modal-zindex: 11000;
}

body h1 {
  font-weight: bold;
}

html.lock-scroll {
  overflow: hidden;

  body {
    overflow: hidden;
  }
}

html,
body,
#__next {
  height: 100lvh;
  font-family: var(--theme-font-family);
}

html {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

// this helps raise specificity without having to use !important
div a,
li a,
span a {
  text-decoration: none;
  color: var(--ui-link);

  &:focus-visible {
    outline: 2px solid var(--ui-orange);
  }

  &:hover {
    text-decoration: none;
    color: var(--ui-hoverState);
  }
}

button {
  border: none;
  font-family: var(--theme-font-family);
  background: none;
  color: inherit;
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visually-hidden-focus {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    background-color: #fff;
    color: #000;
  }
}

.visually-hidden-mobile {
  @include vars.mobileOnly() {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.hide {
  display: none !important;
}

.mobileOnly {
  @include vars.mediaQuery('md') {
    display: none !important;
  }
}

.hideMobile {
  @include vars.mobileOnly() {
    display: none !important;
  }
}

.desktopOnly {
  @include vars.tabletOnly() {
    display: none !important;
  }

  @include vars.mobileOnly() {
    display: none !important;
  }
}

.hidePrint {
  @media print {
    display: none !important;
  }
}

@keyframes skeleload {
  from {
    background-position: -1000px 0;
  }

  to {
    background-position: 1000px 0;
  }
}

.skeleton {
  position: relative;
  overflow: hidden;
  font-size: 1rem;

  &:before {
    animation-name: skeleload;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right,
        #fafafa 52%,
        #eee 75%,
        #fafafa 86%);
    background-size: 1000px 100%;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.s-img:before {
    animation: none;
    background-color: var(--background-lighter);
    border: 1px solid var(--ui-divider);

    &:empty {
      content: "";
      border: 0 none;
      position: absolute;
      width: 100%;
      height: 100%;
      animation-name: skeleload;
      animation-duration: 2.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      background: linear-gradient(to right,
          #fafafa 52%,
          #eee 75%,
          #fafafa 86%);
      background-size: 1000px 100%;
    }
  }



  &.skeletonText {

    &:before {
      height: calc(100% + 5px);
    }
  }
}

svg:has(+ .skeleton) {
  fill: var(--ui-disabledText) !important;
}

// bug in ios safari where the cancel button is not styled
input[type="search"]::-webkit-search-cancel-button {
  color: var(--ui-mutedText);
  height: 24px;
  width: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3E%3Cpath d='m12 13.34 6.33 6.33 1.34-1.34-6.33-6.33 6.33-6.33-1.34-1.34-6.33 6.33-6.33-6.33-1.34 1.34 6.33 6.33-6.33 6.33 1.34 1.34z'/%3E%3C/svg%3E");
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
}