@use 'ui/styles/variables' as vars;

.Container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 2em;

  .Selected {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: space-between;

    .Pills {
      min-height: calc(31px + 1rem);
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}