.RecentSearches {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--ui-divider);
  position: relative;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: auto;
    background-color: #fff;
    min-height: 44px;
    width: 100%;

    .clear {
      margin: 8px;
      width: 28px;
    }

    .Item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      height: 100%;
      padding: 8px 8px;
      flex-wrap: wrap;
      z-index: 1;
      width: 100%;
      overflow: hidden;

      span {
        text-wrap: wrap;
      }

      &:hover {
        background-color: var(--background-faintBlue);
      }

      &:focus-visible {
        background-color: var(--background-faintBlue);
        outline: 2px solid var(--ui-orange);
        outline-offset: -2px;
        z-index: 3;
      }

      &.selected {
        background-color: var(--background-faintBlue);
        outline: 2px solid var(--ui-orange);
        outline-offset: -2px;
        z-index: 3;
      }

      label {
        width: auto;
        height: auto;
      }
    }
  }
}