.Container {
  background-color: #fff;
  min-width: 300px;
}

.TimelineContainer {
  height: 100px;
  width: 100%;
}

.Muted {
  color: var(--ui-mutedText);
  font-weight: normal;
}

