.TimelineContainer {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    overflow: visible;
  }

  svg.timeline {
    margin-bottom: 8px;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #c7c8c9;
    shape-rendering: crispEdges;
  }

  .area-basic {
    fill: #e8f6ff;
  }

  .blot-line-basic {
    fill: transparent;
    stroke: #4994ca;
    stroke-width: 2;
  }

  .area-extra {
    fill: #fdf2e2;
    margin-left: 20px;
  }

  .blot-line-extra {
    fill: transparent;
    stroke: #eab15d;
    stroke-width: 2;
  }

  .slider-line {
    fill: transparent;
    stroke: black;
    stroke-width: 2;
  }

  .focus-circle {
    fill: #fff;
    stroke: #757676;
  }

  .focus-text {
    fill: #343a40;
    font-weight: bold;
    font-size: 0.65rem;
  }

  .slider {
    fill: #1976bb;
    cursor: ew-resize;
  }

  .slider-text {
    font-weight: bold;
    font-size: 0.65rem;
  }

}