.Alert {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  line-height: 1.3em;
  width: 100%;

  &.danger {
    color: var(--alert-danger);
    background-color: var(--alert-dangerBackground);

    svg {
      fill: var(--alert-danger);
    }

    a {
      color: var(--alert-danger);
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: var(--alert-danger);
        font-weight: bold;
      }
    }
  }

  &.success {
    color: var(--alert-success);
    background-color: var(--alert-successBackground);

    svg {
      fill: var(--alert-success);
    }
  }

  &.warning {
    color: var(--ui-peText);
    background-color: var(--alert-warningBackground);

    svg {
      fill: var(--ui-peText);
    }
  }

  &.info {
    background-color: var(--background-lightBlue);

    svg {
      fill: var(--core-ink);
    }
  }

  svg {
    min-width: 24px;
    box-sizing: unset !important;
  }

  .close {
    margin-left: auto;
    cursor: pointer;
  }

  .multiLine {
    .heading {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0px 0px 0px .75rem;
      box-sizing: border-box;
      min-height: 48px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0px;
        margin-top: 0px;
      }

      .icon {
        max-width: 1.5rem;
      }

      &.notDismissable {
        padding-right: 12px;
      }
    }

    .body {
      padding: 0px 1em 1em 3rem;

      ul,
      ol {
        padding-left: 1rem;
      }
    }

    svg {
      height: 100%;

    }

    .close {
      padding: 12px;
    }

  }

  .singleLine {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 12px;
    gap: 12px;

    * {
      margin: 0px;
      padding: 0px;
      line-height: 1.3em;
    }

    &.notDismissable {
      .body {
        padding-right: 12px;
        width: 100%;
      }
    }

    .body {
      margin: 8px 0px;
      line-height: 1.5em;
      align-self: center;
      width: 100%;
    }

    .icon {
      padding: 8px 0px;
    }

    .close {
      padding: 8px 12px;
    }
  }
}