@use 'ui/styles/variables' as vars;

.CountryDropdown {
  margin: 0px 0px;
}

.LocationList {
  padding: 0px 0px;

  @include vars.mobileOnly() {
    // use static height on mobile since in dropdown it comes from bottom and changes in height make everything move around
    height: 420px;
  }
}