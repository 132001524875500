@use 'ui/styles/variables' as vars;

.FooterContainer {
  width: 100%;
  min-height: 100px;
  background-color: var(--background-gray);
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 90px !important;

  @media print {
    min-height: 1em;
  }
  @include vars.mobileOnly() {
    padding-bottom: 50px;
  }

  &.gray {
    .Fray {
      @include vars.mediaQuery('md') {
        display: none;
      }
    }
  }

  .Fray {
    background-image: url('/i/pfray@2x.png');
    background-size: 10px 14px;
    background-repeat: repeat-x;
    width: 100%;
    height: 7px;
    background-position: 0 -7px;
  }

  .divider {
    background-color: var(--core-slate);
    opacity: 40%;
    margin: 24px auto 0 auto !important;
    width: calc(100% - 64px) !important;

    @include vars.mediaQuery('md') {
      max-width: 1256px !important;
      margin: 24px auto 0 auto !important;
    }

    @include vars.mobileOnly() {
      width: 100% !important;
    }
  }

  .footerRow {
    font-size: 16px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 16px;
    width: 100%;
    box-sizing: border-box;

    @include vars.mediaQuery('md') {
      align-self: center;
      max-width: 1320px;
      flex-direction: row;
      padding: 24px 32px 0 32px;
    }

    &.center {
      justify-content: center;
    }

    &.terms {
      padding: 24px 16px 8px 16px;

      .termsContainer {
        min-height: 60px;

        .dropdownContainer {
          margin-bottom: 12px;
        }
      }

      @include vars.mediaQuery('md') {
        padding: 24px 32px 8px 32px;
      }

      @media print {
        padding: 0px 0px 0px 20px;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        margin: 0;

        a {
          color: inherit;
        }
      }

    }

    &.social {
      flex-direction: row;
      list-style-type: none;
      justify-content: flex-start;
      padding: 0;
      gap: 12px;

      @include vars.mediaQuery('md') {
        justify-content: flex-end;
        margin: 0;
      }

      a {
        &:hover {
          color: var(--ui-hoverState);
          text-decoration: none;
        }
      }
    }

    &.logo {
      align-items: center;
      padding-top: 20px;
    }

    &.poweredBy {
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;

      @include vars.mediaQuery('md') {
        text-align: right;
      }

      img {
        margin: 0 auto 20px auto;

        @include vars.mediaQuery('md') {
          margin: 0;
        }
      }

      @include vars.mediaQuery('md') {
        #privacy_link {
          display: inline-block;
        }
      }
    }

    .linkContainer {
      display: flex;

      // flex: 4;
      flex-direction: column;

      @include vars.mediaQuery('md') {
        flex-direction: row;
        width: 100%;
      }

      .linkColumn {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // flex: 1;
        list-style-type: none;

        @include vars.mediaQuery('md') {
          &:first-of-type {
            margin-right: 80px;
          }
        }

        >ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          >li {
            padding: 0 1rem 1rem 0;
          }
        }

        @include vars.mediaQuery('md') {
          &:last-child {
            margin-left: auto;
            text-align: right;
          }
        }
      }
    }

    .socialBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .dropdownContainer {
      color: var(--core-ink);

      span {
        font-size: .875rem;
        margin-right: 4px;
      }

      .dropdown {
        display: inline-block;

        @include vars.mediaQuery('md') {
          display: block;
        }

        .toggle {
          background-color: transparent;
          color: var(--core-ink);
          font-weight: bold;
          border: 0 none;
          // border-radius: 4px;
          text-align: left;
          padding: 0;
          vertical-align: baseline;

          &::after {
            margin-left: 4px;
          }

          &:hover,
          &:focus {
            color: var(--ui-hoverState);
            border: 0 none;
            box-shadow: 0 none;
          }

          &:focus-visible {
            outline: 2px solid var(--ui-orange);
            box-shadow: none;
            outline-offset: 4px;
          }

          &:active {
            color: var(--ui-link);
          }
        }
      }

      .dropdown-item.active {
        background-color: var(--background-lightBlue);
        color: var(--core-ink);
        font-weight: bold;
      }
    }

    .muted {
      font-family: var(--font-family);
      color: var(--ui-mutedText);
      font-size: 14px;
      line-height: 16px;

      a {
        color: var(--ui-mutedText) !important;
      }

      &.legalese {
        margin: 0 0 32px;
      }
    }

    .badge {
      background-color: var(--background-lighter);
      color: var(--core-ink);
      display: inline-block;
      padding: .25em .4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      border-radius: .25rem;
    }

    .focusLink {
      color: var(--core-ink);
      font-size: 1rem;
      font-weight: bold;

      &:hover {
        color: var(--ui-hoverState);
      }

      &:focus-visible {
        outline: 2px solid var(--ui-orange);
        outline-offset: 4px;
        border-radius: 4px;
      }
    }
  }

  //simple footer classes
  &.simpleFooter {
    padding-bottom: 32px !important;

    .terms {
      p {
        text-align: center;

        @include vars.mediaQuery('md') {
          text-align: left;
        }
      }

      @include vars.mediaQuery('md') {
        #privacy_link {
          display: inline-block;
        }
      }
    }

    .social {
      justify-content: center !important;

      .dropdownContainer {
        text-align: center;
      }
    }

    .logo {
      padding-top: 16px;
    }

    .visit {
      @include vars.mobileOnly() {
        align-self: center;
        display: flex;
        flex-direction: column;
      }
    }

  }
}