.Pill {
  font-family: var(--font-family);
  width: fit-content;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid;
  box-sizing: border-box;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  gap: 8px;

  &:hover {
    text-decoration: none !important;
  }

  // sizing
  &.default {
    height: 40px !important;

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  }

  &.small {
    height: 32px;
    font-size: .875rem;
    padding: 0 1rem;

    svg {
      width: 19px;
      height: 19px;
      min-height: 19px;
      min-width: 19px;
    }
  }

  &.large {
    height: 52px;
    padding: .5rem 1.5rem;
    font-size: 1.125rem;
    border-radius: 32px;
  }

  &.tiny {
    height: 20px;
    padding: 0rem 0.25rem;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &.iconOnly {
    &.default {
      width: 40px;
    }

    &.small {
      width: 32px;
    }

    &.large {
      width: 52px;
    }
  }

  &.stretched {
    padding: .5rem 3rem;
  }

  // variants
  &.primary {
    background-color: var(--ui-link);
    color: #fff !important;
    border-color: var(--ui-link);

    svg {
      fill: #fff;
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--ui-hoverState);
    }
  }

  &.outline-primary {
    background-color: #fff;
    color: var(--ui-link) !important;
    border-color: var(--ui-link);

    svg {
      fill: var(--ui-link);
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--ui-link) !important;
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }

  &.outline-primary-transparent {
    background-color: transparent;
    color: var(--ui-link) !important;
    border-color: var(--ui-link);

    svg {
      fill: var(--ui-link);
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--ui-link) !important;
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }

  &.secondary,
  &.outline-secondary {
    background-color: #fff;
    color: var(--core-slate) !important;
    border-color: var(--core-slate);

    svg {
      fill: var(--core-slate);
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--core-slate);
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }

  &.dark-secondary {
    background-color: var(--core-slate);
    color: #fff !important;
    border-color: var(--core-slate);

    svg {
      fill: #fff;
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--core-ink);
    }
  }

  &.ancestry {
    background-color: var(--ui-ancestryGreen);
    color: #fff !important;
    border-color: var(--ui-ancestryGreen);

    svg {
      fill: #fff;
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--ui-ancestryHover);
    }
  }

  &.outline-danger {
    background-color: #fff;
    color: var(--core-red) !important;
    border-color: var(--core-red);

    svg {
      fill: var(--core-red);
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--core-red);
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }

  &.outline-transparent,
  &.transparent {
    background-color: transparent;
    color: var(--core-ink) !important;
    border-color: var(--core-ink);

    svg {
      fill: var(--core-ink);
    }

    &:hover:not(:disabled, .disabled),
    &.hover,
    &.selected {
      background-color: var(--core-ink);
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }

  &.transparent {
    border: none;
  }

  // state overrides
  // XXX - this is needed after the variant rules. otherwise, the variant rules override these
  &:disabled,
  &.disabled {
    background-color: rgba(#b1b1b1, .3);
    border-color: rgba(#b1b1b1, .3);
    color: rgba(#2d2d2d, .3) !important;

    svg {
      fill: var(--ui-disabledText)
    }
  }

  &:focus-visible,
  &.focus {
    outline: 0;
    border-color: var(--ui-orange);
    box-shadow: 0 0 0px 2px var(--ui-orange);
  }

}

.PillText {
  text-align: center;
  margin-inline-start: 0px;

  &.icon {
    margin-inline-start: 4px;
  }
}