.Paginator {
  //ul
  display: flex;
  margin: 0px 0px;
  padding: 0px 0px;
  position: relative;
  width: fit-content;

  li {
    list-style: none;
    margin: 0px 0px;
    padding: 0px 0px;

    &:focus-within {
      button {
        z-index: 2;
      }
    }

    &.Page {

      button,
      a {
        margin-left: -1px;
      }

      &~.Page {

        button,
        a {
          // border-left-width: 1px;
        }
      }

      button,
      a {
        border-radius: 0px;
      }
    }

    &:first-of-type {

      button,
      a {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    &:last-of-type {

      button,
      a {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -1px;
      }
    }
  }

  .Prev {}

  .Next {}
}