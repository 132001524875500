.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;

  &.small {
    width: 1rem;
    height: 1rem;
    border-width: 0.19em;
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}