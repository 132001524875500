@use 'ui/styles/variables' as vars;

.LocationDropdown {
  @include vars.mediaQuery('md') {
    min-width: 500px;
  }
}

.LocationAutocomplete {
  position: relative;
  z-index: 200;
  // max-width: 80vw;
  // width: fit-content;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  // min-height: 300px;
  min-width: 200px;
}

.Muted {
  color: var(--ui-mutedText);
  font-weight: normal;
}

.Hint {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent !important;
  color: var(--ui-mutedText);
}