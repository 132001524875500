.Pagination {
  background-color: #fff;
  width: 100%;
  padding: 16px;
  max-width: 100%;
  overflow: hidden;
}

.Page {
  // active item renders span instead of anchor

  span {
    background-color: var(--ui-link) !important;
  }

  a {
    background-color: var(--background-lighter);
    color: var(--core-ink);

    &:hover {
      background-color: var(--background-gray);
      color: var(--core-ink);
    }

    &:focus {
      background-color: var(--background-gray);
      color: var(--core-ink);
    }
  }
}

.Row {
  background-color: #fff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--ui-mutedText);
    font-size: 13px;
    font-weight: 600;
  }
}

.Selected {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ui-divider);
  margin-bottom: 16px;
  padding: 0px 16px;

  .Pills {
    min-height: calc(31px + 1rem);
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;
  }
}