@use 'ui/styles/variables' as vars;

.RightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include vars.mediaQuery('md') {
    padding-right: 0.5rem;
  }

  @media print {
    display: none;
  }

}


.LeftContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  flex-direction: row;


  @include vars.mediaQuery('lg') {
    flex-direction: row-reverse;
  }

  .tagline {
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    display: none;

    @include vars.mediaQuery('lg') {
      display: block;
    }
  }

}

.HeaderContainer {
  font-size: 1em;
  min-height: 3.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  background: var(--core-slate);
  align-items: center;
  justify-content: space-between;

  &.overlayHero {
    background-color: var(--core-slate);

    &.mainSite {
      position: absolute;
      top: 0px;
      z-index: 2;
      background-color: var(--core-slate);
      margin-top: 54px;
      margin-top: 0px;
      background: linear-gradient(0deg, rgba(45, 45, 45, 0) 10%, rgb(45, 45, 45) 97%);
    }

    &.noIframe {
      margin-top: 0px; // No iframe disables the margin
      //background: var(--core-slate);
    }
  }

  &.mainSite {
    background: var(--core-ink);
  }

  @media print {
    background: #fff;
  }

}

.HeroHeaderContainer {
  background-image: url('/i/homepage_header_photo-4.jpeg');
  background-position: 50% 100%;
  min-height: 450px;
  max-height: 731px;
  background-size: cover;
}

.HeaderButtonLink {
  line-height: 40px;
  padding: 0 1em;
  border-radius: 500px;
  color: #fff;

  &:hover {
    background-color: rgba(239, 239, 239, 0.1);
    color: #fff;
    text-decoration: none;
  }

  &:focus {
    color: #fff;
  }

  &:active {
    color: #fff;
  }
}

.ClippingButton {
  margin-right: 0.5rem;
  align-items: center;
  display: none;
  text-decoration: none;

  @include vars.mediaQuery('xs') {
    display: inherit;
  }

}

.AlertBanner {
  background: #439cd4;
  color: #fff;
  padding: 3px;
  text-align: center;
}

.HideMobile {
  display: none;

  @include vars.mediaQuery('md') {
    display: inherit;
  }
}

.DesktopText {
  font-weight: 700;
  padding-left: 0.5rem;
  display: none;

  @include vars.mediaQuery('md') {
    display: inline-block;
  }

}

.Uppercase {
  text-transform: uppercase;
}